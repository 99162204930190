<template>
  <div v-if="!mostraContrato" class="card shadow mb-4">
    <div class="card-body card-modal-default card-carnedigital">
      <div class="card-content">
        <span :id="`mensagem-${contrato.numeroContrato}`">O contrato {{ contrato.numeroContrato }} do cliente {{ cpfCnpj
        }} está vinculado a outro lojista.</span>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="card shadow mb-4">
      <div class="card-body card-modal-default card-carnedigital">
        <div class="card-content">
          <div class="col1">
            <div class="title">
              <small>Financiamento</small>
              <h2 class>{{ contrato.descricao }}</h2>
            </div>
            <div class="number">
              <small>Número do Contrato</small>
              <p>{{ contrato.numeroContrato }}</p>
            </div>
          </div>
          <div class="col2">
            <div class="chart-default" style="width: 140px;height: 140px;">
              <canvas :id="`grafico-financiamento-${contrato.numeroContrato}`"></canvas>
            </div>
            <div class="text-center lh-1">
              <small>
                <strong>{{ ((100 * contrato.qtdParcelasPagas) / contrato.qtdParcelas).toFixed(0) }}% <br></strong>
                <span>Parcelas pagas: <br></span>
                <strong>{{ contrato.qtdParcelasPagas }}/{{ contrato.qtdParcelas }}</strong>
              </small>
            </div>
          </div>
        </div>
        <!-- fim principal de iten do contrato -->
        <div class="card-bottom-button">
          <div class="text-center">
            <button v-on:click="downloadPDF" type="button" class="btn btn-link">
              <div class="spinner-border" role="status" v-if="carregando">
                <span class="sr-only">Carregando...</span>
              </div>
              Ver em PDF (Imprimir)
            </button>
            <p class="alert alert-warning p-2" v-if="errors.length">
            <ul>
              <li v-for="(error, index) in errors" v-bind:key="index">{{ error }}</li>
            </ul>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ApiPortalAutoAtendimento from "../api_portal_auto_atendimento/src/index";
import AuthServiceContext from "../services/AuthService";
import Chart from 'chart.js';

export default {
  name: 'CarneDigitalItem',
  data() {
    return {
      authService: AuthServiceContext.getInstance(),
      mostraContrato: true,
      processing: false,
      errors: [],
    }
  },
  computed: {
    carregando: function () {
      return this.processing;
    },
  },
  props: {
    contrato: Object
  },
  methods: {
    downloadPdfBase64(base64String, filename) {
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      var blob = new Blob([byteArray], { type: 'application/pdf' });
      if (window.navigator.msSaveBlob) { // // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
        window.navigator.msSaveOrOpenBlob(blob, filename);
      }
      else {
        var a = window.document.createElement("a");
        window.URL = window.webkitURL || window.URL;
        a.href = window.URL.createObjectURL(blob, { type: "application/pdf", oneTimeOnly: true });
        // a.download = filename;
        a.target = '_blank';
        document.body.appendChild(a);
        a.click();  // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
        document.body.removeChild(a);
      }
    },
    navigate(href) {
      var link = document.createElement('a');
      link.href = href;
      link.setAttribute('target', '_blank');
      try {
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      } catch (error) {
        link.click();
      }
    },
    downloadPDF(event) {
      if (event) {
        event.preventDefault();
      }
      if (this.processing) {
        return;
      }
      this.processing = true;
      let config = this.authService.config();
      let cobrancaApi = new ApiPortalAutoAtendimento.CobrancaApi();
      let defaultClient = ApiPortalAutoAtendimento.ApiClient.instance;
      defaultClient.basePath = config.endpoints.autoatendimento;
      defaultClient.authentications.oauth2.accessToken = this.authService.accessToken()
      let userData = this.authService.userData();
      if (this.authService.isFastAccessCompleto()) {
        userData.CpfCnpj = this.cpfCnpj;
      }

      let mensagemErroVisualizarCarne = "Caso não consiga visualizar seu carnê, solicitar o documento pelo telefone 4020 3300. - Não foi possível gerar o carnê.";
      this.errors = [];

      cobrancaApi.ConsultaContratoProduto(userData.Empresa, this.contrato.agencia, this.contrato.numeroContrato, (error, data, response) => {
        this.processing = false;
        if (error) {
          this.errors.push(mensagemErroVisualizarCarne);
        }
        else if (!response.body) {          
            cobrancaApi.urlImpressaoCarne(userData.Empresa, userData.CpfCnpj, this.contrato.numeroContrato, (error, data, response) => {
              this.processing = false;
              if (error) {
                this.errors.push(mensagemErroVisualizarCarne);
              }
              else {
                if (
                  !response.body.sucesso && 
                  response.body.erros !== null &&
                  Array.isArray(response.body.erros) &&
                  response.body.erros.length !== 0
                ) {
                  //response.body.erros.forEach(erro => { this.errors.push(erro); });
                  this.errors.push(mensagemErroVisualizarCarne);
                }
                else if (response.body.pdf) {
                  let pdfBase64 = response.body.pdf;
                  this.downloadPdfBase64(pdfBase64, `carne-${this.contrato.numeroContrato}.pdf`);
                }
                else if (response.body.urlPainelCtd !== undefined)
                {
                  this.navigate(response.body.urlPainelCtd);
                }
                else
                {
                  this.errors.push(mensagemErroVisualizarCarne);
                }
              }
            });
        }
        else {
          this.errors.push("Prezado cliente, para solicitação de boleto, por gentileza, enviar um e-mail para: administrativo_cobranca@bancodigimais.com.br informando nome completo e CPF.");
        }
      });
    }
  },

  mounted() {
    let userData = this.authService.userData();
    this.cpfCnpj = localStorage.cpfCnpj;
    if (this.authService.isFastAccessCompleto()) {
      this.cpfCnpj = localStorage.cpfCnpj;
      if (!userData) {
        userData = JSON.parse(localStorage.userData);
      }
      this.mostraContrato = userData.LojistasLojas[0].Item1 == this.contrato.lojista;
    }

    this.$nextTick(() => {
      if (this.mostraContrato) {
        (Chart.defaults.global.defaultFontFamily = "Nunito"),
          //'-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif';
          Chart.defaults.global.defaultFontColor = "#333333";

        // Grafico Financiamento 01
        let ctx = document.getElementById("grafico-financiamento-" + this.contrato.numeroContrato);

        new Chart(ctx, {
          type: "doughnut",
          data: {
            labels: ["Pago", "Em aberto"],
            datasets: [{
              data: [this.contrato.qtdParcelasPagas, this.contrato.qtdParcelas - this.contrato.qtdParcelasPagas],
              backgroundColor: ['#030065', '#F10707'],
              hoverBackgroundColor: ['#0700de', '#ff4c4c'],
              hoverBorderColor: "rgba(234, 236, 244, 1)",
            }]
          },
          options: {
            maintainAspectRatio: false,
            tooltips: {
              backgroundColor: "rgb(255,255,255)",
              bodyFontColor: "#333333",
              borderColor: "#dddfeb",
              borderWidth: 1,
              xPadding: 10,
              yPadding: 10,
              displayColors: false,
              caretPadding: 10
            },
            legend: {
              display: false
            },
            cutoutPercentage: 80
          }
        });
      }
    });
  }
}
</script>